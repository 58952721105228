import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color, height, width }) => (
  <SvgIcon
    viewBox='0 0 11 14'
    style={{
      height: height || '14px',
      width: width || '11px',
    }}
  >
    <g id='map-marker-2-copy-2' transform='translate(0.3149414 0.3149414)'>
      <path
        d='M10.08 5.04C10.08 2.25648 7.82351 2.79776e-16 5.04 0C2.25648 -1.39888e-16 5.59552e-16 2.25648 0 5.04C0 6.93 1.701 9.387 5.04 12.411C8.379 9.45 10.08 6.93 10.08 5.04Z'
        id='Path'
        fill={color || '#D6D8DF'}
        stroke='none'
      />
      <path
        d='M1.89 3.78C2.93382 3.78 3.78 2.93382 3.78 1.89C3.78 0.846182 2.93382 0 1.89 0C0.846182 0 0 0.846182 0 1.89C0 2.93382 0.846182 3.78 1.89 3.78Z'
        transform='translate(3.149902 3.149902)'
        id='Oval'
        fill={color === '#fff' ? '#0041E5' : '#FFFFFF'}
        stroke='none'
      />
    </g>
  </SvgIcon>
);
